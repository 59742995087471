import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import Lightbox from "yet-another-react-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import HeadTags from 'shared/HeadTags';
import Breadcrumbs from 'shared/Breadcrumbs';
import "yet-another-react-lightbox/styles.css";
import 'swiper/css';

const docs = [
    {
        name: "Правила предоставления платных медицинских услуг",
        src: require('assets/docs/dogovor-na-okazanie-med-uslug.docx')
    },
    {
        name: "Правила поведения пациентов",
        src: require('assets/docs/pravila-priema-pacientov.docx')
    },
    {
        name: "Информированное согласие",
        src: require('assets/docs/informirovannoe-soglasie.docx')
    },
    {
        name: "Политика в отношении обработки персональных данных",
        src: require('assets/docs/politika-personalnyh-dannyh.doc')
    },
    {
        name: "График приема граждан",
        src: require('assets/docs/grafik-priema-grazhdan.docx')
    },
]

const sliderImages = [
	{ 
		small: require('assets/img/information/info-img_01_sm.jpg'),
		big: require('assets/img/information/info-img_01.jpg')
	},
    { 
		small: require('assets/img/information/info-img_02_sm.jpg'),
		big: require('assets/img/information/info-img_02.jpg')
	},
    { 
		small: require('assets/img/information/info-img_03_sm.jpg'),
		big: require('assets/img/information/info-img_03.jpg')
	},
    { 
		small: require('assets/img/information/info-img_04_sm.jpg'),
		big: require('assets/img/information/info-img_04.jpg')
	},
    { 
		small: require('assets/img/information/info-img_05_sm.jpg'),
		big: require('assets/img/information/info-img_05.jpg')
	},
];

const titleImg = require("assets/img/information/info-img_title.jpg");

const InfoPage = () => {

    const [galleryIndex, setGalleryIndex] = useState(-1);
    let bigImages = sliderImages.map(el => {return ({src: el.big})});
    const handleGalleryClick = (index: number) => setGalleryIndex(index);
    
 
    return (
    <div className="information">
        <HeadTags
            title='Информация'
            metaDescription='Информация'
        />
        <div className="container">
            <Breadcrumbs pageName={'Информация'}/>
            <h1>Информация</h1>
        </div>

        <div className="information_block">
            <div className="container">
                <div className="information_grid">
                    <div className="information_grid__img">
                        <img src={titleImg} alt="Выписка из реестра лицензий" />
                    </div>
                    <div className="information_grid__docs">
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">Название организации</span>
                            <span>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ГРАФ&nbsp;ЯГОДА»</span>
                        </div>
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">Юридический адрес организации</span>
                            <span>241050, РОССИЯ, БРЯНСКАЯ ОБЛ, Г&nbsp;БРЯНСК, УЛ&nbsp;УРИЦКОГО,&nbsp;9А, ОФИС&nbsp;7</span>
                        </div>
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">ИНН</span>
                            <span>3200003660</span>
                        </div>
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">КПП</span>
                            <span>320001001</span>
                        </div>
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">ОГРН/ОГРНИП</span>
                            <span>1233200005950</span>
                        </div>
                        <div className="information_grid__elem">
                            <span className="information_grid__sub">Документы</span>
                            <ul className="information_docs__list">
                                {docs.map((el, i) => {
                                    return (
                                    <li key={i}>
                                        <a className="information_docs__item" href={el.src} download>{el.name}</a>                                        
                                    </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="information_gallery">
            <div className="container">
                <div className="information_gallery__slider">
                    <Swiper
                        className="information-slider"
                        slidesPerView={4}
                        modules={[Navigation, Pagination]}
                        loop={true}
                        autoplay={{
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '#next',
                            prevEl: '#prev',
                        }}
                        breakpoints={{
                            "300": {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            },
                            "420": {
                            slidesPerView: 2,
                            spaceBetween: 10,
                            },
                            "768": {
                            slidesPerView: 3,
                            spaceBetween: 20,
                            },
                            "1140": {
                            slidesPerView: 4,
                            spaceBetween: 25,
                            },
                        }}
                        >
                        {sliderImages.map((el, i) => {
                            return (
                            <SwiperSlide key={i}>
                                <div className="information-slider__item" onClick={() => handleGalleryClick(i)}>
                                    <img alt="Документы - фото" src={el.small} title="Документы" />
                                </div>
                            </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <div className="slider-prev" id="prev"></div>
                    <div className="slider-next" id="next"></div>
                </div>
            </div>
        </div>
        <Lightbox
            slides={bigImages}
            open={galleryIndex >= 0}
            index={galleryIndex}
            close={() => setGalleryIndex(-1)}
        />
    </div>
    )
};

export default InfoPage;