import { NavLink } from 'react-router-dom';

const Menu = () => {
  return (
    <ul className="menu" itemScope itemType="http://schema.org/SiteNavigationElement">
      <li><NavLink to="/" itemProp="url">Главная</NavLink></li>
      <li><NavLink to="/uslugi" itemProp="url">Наши услуги</NavLink></li>
      <li><NavLink to="/ceny" itemProp="url">Цены</NavLink></li>
      <li><NavLink to="/otzyvy" itemProp="url">Отзывы</NavLink></li>
      <li><NavLink to="/informaciya" itemProp="url">Информация</NavLink></li>
      <li><NavLink to="/kontakty" itemProp="url">Контакты</NavLink></li>
    </ul>
  );
};

export default Menu;