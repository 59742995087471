import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import Menu from 'features/Menu/Menu';
import { ViewportContext } from 'app/viewportContext';
import {
	CSSTransition,
	TransitionGroup
} from 'react-transition-group';

import Banner from 'features/Banner/Banner'

const logoImg = require("assets/img/logo.svg").default;
const telegram = require("assets/img/telegram.svg").default;
const vk = require("assets/img/vk.svg").default;
const whatsapp = require("assets/img/whatsapp.svg").default;


const Header = ({setPaddingtop}: { setPaddingtop: (height: number) => void}) => {
	const breakpoint = 480;
	const width = useContext(ViewportContext);
	
	const location = useLocation();
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isFixed, setIsFixed] = useState(false);
	const headerRef = useRef(null);
	let startHeaderHeight = 0;
	const navigate = useNavigate();
	const header = document.querySelector('header');

	const handleScroll = () => { 
		if (window.scrollY > headerRef.current.offsetHeight) {
			setIsFixed(true);
			if(location.pathname !== '/') {
				setPaddingtop(startHeaderHeight);
			}
		} else {
			setIsFixed(false);
			setPaddingtop(0);
		}
	};

	useEffect(() => {
		if (header) {
			if(location.pathname === '/') header.classList.add('fixheader');	 
			else header.classList.remove('fixheader');			
		}
	}, [navigate]);	

	useEffect(() => {		
		startHeaderHeight = headerRef.current.offsetHeight;
		
		window.addEventListener('scroll', handleScroll)
		return () => {
			startHeaderHeight = 0;
			window.removeEventListener('scroll', handleScroll)
		}
	}, []);

	useEffect(() => {
		setIsMobile(width <= breakpoint);		
	}, [width]);

	useEffect(() => {
		setShowBurgerMenu(false);
	}, [location]);

	return (
		<>
			<header ref={headerRef} className={ [isFixed ? 'fixed' : '' , location.pathname === '/' ? 'fixheader' : '' ].join(' ')}>
				<div className={`container header ${showBurgerMenu ? 'is-active' : ''}`}>
					<Link to="/" className="header_logo">
						<img src={logoImg} alt="Логотип компании" title="Граф ягода" width="128" />
					</Link>
					<div className="slogan header_slogan">салон красоты</div>
					<div className="header_contacts">
						<a className="contact-elem contact-elem--phone" href="tel:+74832721121">72-11-21</a>
						<a className="contact-elem contact-elem--adr" href="/kontakty#map">г.&nbsp;Брянск, ул.&nbsp;Советская, д.&nbsp;122</a>
					</div>
					<div className="header_menu anim" id="header_menu">
						<TransitionGroup component={null}>
							<CSSTransition timeout={{
								enter: 150,
								exit: 150,
							}}>
								<Menu />
							</CSSTransition>
						</TransitionGroup>
						<a className="entry-btn" href="https://n863993.yclients.com/company/741738/select-services?o=s14976815,14523269,14523307" target="_blank" rel="noreferrer">Записаться в&nbsp;салон</a>
					</div>
					<div className="header_buttons">
						<a className="entry-btn" href="https://n863993.yclients.com/company/741738/select-services?o=s14976815,14523269,14523307" target="_blank" rel="noreferrer">Записаться в&nbsp;салон</a>
						<div className="header_socials">
							<a className="messenger telegram" href="https://t.me/graf_yagoda_32" target="_blank" rel="noreferrer"><img src={telegram} alt="Telegram" width="24" /></a>
							<a className="messenger vk" href="https://vk.com/grafyagoda" target="_blank" rel="noreferrer"><img src={vk} alt="VK" width="24" /></a>
							{isMobile
								? <a className="messenger whatsapp" href="whatsapp://send?phone=+79004849900"><img src={whatsapp} alt="whatsapp" width="46" /></a>
								: <a className="messenger whatsapp" href="https://web.whatsapp.com/send?phone=+79004849900" target="_blank" rel="noreferrer"><img src={whatsapp} alt="whatsapp" width="46" /></a>
							}
						</div>
					</div>
					<button className="menu-toggle" onClick={() => setShowBurgerMenu(state => !state)}><span>&nbsp;</span></button>
				</div>
			</header>
			{/* {
				location.pathname !== '/' ?  (
					<Banner mobile={isMobile}/>						
				) 
				: null
			} */}
			
		</>
	)
}
export default Header;